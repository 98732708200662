import React, { useState, useRef } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Autocomplete,
} from "@mui/material";
import { db } from "./firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { Timestamp, GeoPoint, collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { StandaloneSearchBox } from "@react-google-maps/api";
// import SpotifyWebApi from "spotify-web-api-js";

// const spotifyApi = new SpotifyWebApi();

function EventForm() {
  const [artist, setArtist] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState(null);
  const [organizer, setOrganizer] = useState("");
  const [price, setPrice] = useState("");
  const [venue, setVenue] = useState("");
  const [notes, setNotes] = useState("");
  const [existingEvents, setExistingEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();
  const fileInputRef = useRef();
  const [eventDates, setEventDates] = useState([
    { date: '', time: '', contact: '' }
  ]);

  // const getSpotifyToken = async () => {
  //   const clientId = "a40e516ce15c417ab408c392be103ca8"; // Replace with your Client ID
  //   const clientSecret = "2cc2fc931da9484bad3b62b1f6d5d8eb"; // Replace with your Client Secret
  //   const response = await fetch("https://accounts.spotify.com/api/token", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //       Authorization: "Basic " + btoa(`${clientId}:${clientSecret}`),
  //     },
  //     body: "grant_type=client_credentials",
  //   });
  //   const data = await response.json();
  //   return data.access_token;
  // };

  // const getPreviewUrl = async (artistName) => {
  //   try {
  //     // Only proceed if we have a non-empty artist name
  //     if (!artistName || artistName.trim() === '') {
  //       console.log("Artist name is empty, cannot fetch preview URL");
  //       return null;
  //     }
      
  //     console.log("Searching for Spotify preview for artist:", artistName);
  //     const token = await getSpotifyToken();
  //     spotifyApi.setAccessToken(token);
      
  //     // Search for tracks by the artist
  //     const result = await spotifyApi.searchTracks(`artist:${artistName}`, { limit: 10 });
  //     console.log("Spotify search result:", result);
      
  //     const trackWithPreview = result.tracks.items.find(track => track.preview_url);
  //     return trackWithPreview ? trackWithPreview.preview_url : null;
  //   } catch (error) {
  //     console.error("Error fetching Spotify preview:", error);
  //     return null;
  //   }
  // };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setLocation(new GeoPoint(place.geometry.location.lat(), place.geometry.location.lng()));
      let city, state;
      place.address_components.forEach((component) => {
        if (component.types.includes("locality") || component.types.includes("sublocality")) {
          city = component.long_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          state = component.short_name;
        }
      });
      if (!city) {
        place.address_components.forEach((component) => {
          if (component.types.includes("neighborhood")) {
            city = component.long_name;
          }
        });
      }
      if (city && state) {
        setCity(`${city}, ${state}`);
      }
      setVenue(place.name);
      setAddress(place.formatted_address.split(",")[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!image) {
      console.log("No file selected");
      return;
    }

    try {
      setLoading(true);

      const fileExtension = image.name.split(".").pop();
      // Use first date for the file name
      const formattedDate = eventDates[0].date.replace(/-/g, "");
      const customFileName = `${artist.replace(/\s+/g, "-")}_${formattedDate}.${fileExtension}`;

      const storage = getStorage();
      const storageRef = ref(storage, `images/${customFileName}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.error("Error uploading image:", error);
          alert("Error uploading image. Please try again.");
          setLoading(false);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            
            let eventData;

            // If it's a single date entry
            if (eventDates.length === 1) {
              // Use the old structure with date, time, and contact
              const [year, month, day] = eventDates[0].date.split("-");
              const [hours, minutes] = eventDates[0].time.split(":");
              const eventDate = new Date(year, month - 1, day, hours, minutes);
              
              eventData = {
                artist,
                title,
                image: downloadURL,
                address,
                city,
                category,
                location,
                organizer,
                price,
                venue,
                date: Timestamp.fromDate(eventDate),
                contact: eventDates[0].contact,
                notes,
              };
            } else {
              // Multiple dates - use new structure
              const dates = eventDates.map(entry => {
                const [year, month, day] = entry.date.split("-");
                const [hours, minutes] = entry.time.split(":");
                const eventDate = new Date(year, month - 1, day, hours, minutes);
                return {
                  date: Timestamp.fromDate(eventDate),
                  contact: entry.contact
                };
              });

              eventData = {
                artist,
                title,
                image: downloadURL,
                address,
                city,
                category,
                location,
                organizer,
                price,
                venue,
                dates: dates,
                notes,
              };
            }

            const eventsRef = collection(db, "events1");
            await addDoc(eventsRef, eventData);

            // Reset form
            setArtist("");
            setTitle("");
            setImage(null);
            setAddress("");
            setLocation(null);
            setCity("");
            setCategory("");
            setOrganizer("");
            setPrice("");
            setVenue("");
            setEventDates([{ date: '', time: '', contact: '' }]);  // Reset to single empty date entry
            setNotes("");
            setExistingEvents([]);
            if (fileInputRef.current) {
              const inputElement = fileInputRef.current.querySelector("input");
              if (inputElement) inputElement.value = "";
            }

            alert("Event added successfully!");
          } catch (error) {
            console.error("Error adding event:", error);
            alert("Error adding event. Please try again.");
          } finally {
            setLoading(false);
          }
        }
      );
    } catch (error) {
      console.error("Error in form submission:", error);
      alert("Error submitting form. Please try again.");
      setLoading(false);
    }
  };

  const searchExistingEvents = async (searchTerm) => {
    if (!searchTerm || searchTerm.length < 2) {
      setExistingEvents([]);
      return;
    }
    setLoading(true);
    try {
      const eventsRef = collection(db, "events1");
      const q = query(
        eventsRef,
        where("artist", ">=", searchTerm),
        where("artist", "<=", searchTerm + "\uf8ff")
      );
      const querySnapshot = await getDocs(q);
      const events = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        displayText: `${doc.data().artist} - ${doc.data().city} - ${new Date(doc.data().dates[0].date.seconds * 1000).toLocaleDateString()}`,
      }));
      setExistingEvents(events);
    } catch (error) {
      console.error("Error searching events:", error);
    }
    setLoading(false);
  };

  // Add function to handle adding new date entry
  const addDateEntry = () => {
    setEventDates([...eventDates, { date: '', time: '', contact: '' }]);
  };

  // Add function to handle removing a date entry
  const removeDateEntry = (index) => {
    const newDates = eventDates.filter((_, i) => i !== index);
    setEventDates(newDates);
  };

  // Add function to update specific date entry
  const updateDateEntry = (index, field, value) => {
    const newDates = [...eventDates];
    newDates[index][field] = value;
    setEventDates(newDates);
  };

  return (
    <>
      <Typography
        align="center"
        sx={{
          fontFamily: "passion one",
          color: "var(--secondary-color)",
          marginTop: "18vh",
          fontSize: "20px",
        }}
      >
        EVENT SUBMISSION FORM
      </Typography>
      <form onSubmit={handleSubmit} className="event-form">
        <div style={{ display: "flex", gap: "20px" }}>
          <Autocomplete
            fullWidth
            freeSolo
            value={artist}
            options={existingEvents}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.displayText
            }
            loading={loading}
            onInputChange={(event, newInputValue) => {
              setArtist(newInputValue);
              searchExistingEvents(newInputValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Artist" variant="outlined" />
            )}
            renderOption={(props, option) => (
              <li {...props} style={{ color: "red" }}>
                {option.displayText}
              </li>
            )}
          />
          <TextField
            label="Title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
          />
        </div>

        <TextField
          type="file"
          ref={fileInputRef}
          onChange={handleImageChange}
          fullWidth
        />

        <div style={{ display: "flex", gap: "20px" }}>
          <TextField
            label="Organizer"
            type="text"
            value={organizer}
            onChange={(e) => setOrganizer(e.target.value)}
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel id="event-category">Category</InputLabel>
            <Select
              labelId="event-category"
              label="Category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <MenuItem value="concert">Concert</MenuItem>
              <MenuItem value="art">Art</MenuItem>
              <MenuItem value="entertainment">Entertainment</MenuItem>
              <MenuItem value="show">Show</MenuItem>
              <MenuItem value="comedy">Comedy</MenuItem>
              <MenuItem value="souk">Souk</MenuItem>
              <MenuItem value="festival">Festival</MenuItem>
            </Select>
          </FormControl>
        </div>

        <StandaloneSearchBox
          onLoad={(ref) => (inputRef.current = ref)}
          onPlacesChanged={handlePlaceChanged}
        >
          <TextField
            label="Venue"
            type="text"
            value={venue}
            onChange={(e) => setVenue(e.target.value)}
            fullWidth
          />
        </StandaloneSearchBox>

        <div style={{ display: "flex", gap: "20px" }}>
          <TextField
            label="Starting price"
            type="text"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            fullWidth
          />
        </div>

        <div>
          {eventDates.map((dateEntry, index) => (
            <div key={index} style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ddd', borderRadius: '4px' }}>
              <div style={{ display: "flex", gap: "20px", marginBottom: '10px' }}>
                <TextField
                  type="date"
                  value={dateEntry.date}
                  onChange={(e) => updateDateEntry(index, 'date', e.target.value)}
                  fullWidth
                />
                <TextField
                  type="time"
                  value={dateEntry.time}
                  onChange={(e) => updateDateEntry(index, 'time', e.target.value)}
                  fullWidth
                />
              </div>
              <TextField
                label="Reservation"
                type="text"
                value={dateEntry.contact}
                onChange={(e) => updateDateEntry(index, 'contact', e.target.value)}
                fullWidth
                sx={{ marginBottom: '10px' }}
              />
              {index > 0 && (
                <Button
                  type="button"
                  variant="outlined"
                  color="error"
                  onClick={() => removeDateEntry(index)}
                  size="small"
                >
                  Remove Date
                </Button>
              )}
            </div>
          ))}
          <Button
            type="button"
            variant="outlined"
            onClick={addDateEntry}
            sx={{ marginBottom: '20px' }}
          >
            Add Another Date
          </Button>
        </div>

        <TextField
          label="Description"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          multiline
          rows={4}
          fullWidth
        />
        <Button
          type="submit"
          variant="contained"
          className="form-buttons"
          disabled={loading}
        >
          {loading ? "Submitting..." : "ADD EVENT"}
        </Button>
      </form>
    </>
  );
}


export default EventForm;