import { useEffect } from 'react';

const EventRedirect = () => {


  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Detect platform
    if (/android/i.test(userAgent)) {
      // Redirect to Play Store
      window.location.href = 'https://play.google.com/store/apps/details?id=nuun.app';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // Redirect to App Store
      window.location.href = 'https://apps.apple.com/app/nuun-live-events/id6736663966';
    } else {
      // Fallback for other devices (e.g., desktop) - could show a webpage
      window.location.href = 'https://www.nuunapp.com'; // Or a custom page
    }
  }, []);

  return (
    <div>
      <p>Redirecting to the app store...</p>
    </div>
  );
};
  
export default EventRedirect;